








import Vue from "vue";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {},
  methods: {
    smartScale() {
      const width = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      let scaleWidth = width;
      if (width / height > 1920 / 1080) {
        scaleWidth = height * (1920 / 1080);
      }
      scaleWidth =
        scaleWidth < 1300
          ? scaleWidth + (1300 - scaleWidth) * 0.625
          : scaleWidth;
      document.documentElement.style.fontSize = scaleWidth / 19.2 + "px";
      document.body.style.fontSize = 16 + "px";
      dispatch.appCheckMobile();
    }
  },
  created() {
    this.smartScale();
    window.addEventListener("resize", this.smartScale);
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
});
