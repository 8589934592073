




































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VHeader",
  computed: {
    ...mapState("app", {
      isMobile: "isMobile",
      language: "language",
      isCN: "isCN"
    })
  },
  methods: {
    onItemClick(path: string) {
      this.$router.push(path);
    },
    onOpen(url: string) {
      window.open(url, "_blank");
    },
    onAMClick() {
      window.open("https://www.angrymiao.com/", "_blank");
    },
    onCartClick() {
      window.open(
        this.isCN
          ? "https://shop210009691.taobao.com/"
          : "https://store.dry---studio.com/",
        "_blank"
      );
    }
  }
});
